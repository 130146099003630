import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as constants from '../app.constant';
import deviceStore from '../store/deviceStore';
import loaderStore from '../store/loaderStore';
import planStore from '../store/planStore';
import customerStore from '../store/customerStore';
import LandingComponent from '../components/landing/landing.component';
import Layout from '../components/shared/layout';
import * as ProdConfig from '../service/prod-config'
import * as xmlParser from '../service/xml-parser';
import orderStore from "../store/orderStore";

const IndexPage = () => {

  const [isCustomerCanProceed, setMandatoryFlag] = useState(false);
  let count = 0;
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('isFromProductInfoPage'))) {
      localStorage.clear();
      customerStore.clear();
      deviceStore.clear();
      planStore.clear();
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('token');
      const mode = urlParams.get('mode') || 0;
      getData(mode);
      if (myParam) {
        getXMLFromToken(myParam, urlParams.get('mode') || mode);
      }
      else {   // if someone opened page without token
        getProductDetail(false)
        setMandatoryFlag(false);
      }
    }
    else setMandatoryFlag(true);   //as it is coming from next page so it is already a valid situation
  }, []);

  const productList = useStaticQuery(graphql
    `query MyQuery {
      allProductList(filter: {clientProductName: {ne: null}}) {
        nodes {
          name: clientProductName
          coverageDesc: clientProductDescription
          pricingType
          productId: productCode
          productName
          tenure
          tenureType
          plans {
            merchantId
            currency
            devicePriceEndingRange
            devicePriceStartingRange
            retailPriceValueWithTax: totalRRPValueWithTax
            bandNo
            repairServiceFee
            replacementServiceFee
          }
        }
      }
    }`
  )

  const getData = async (mode) => {
    let fetched_device_make = '';
    const detailsObj = xmlParser.getParsedXMLObj();
    // const validData = xmlParser.checkMandatoryFields(detailsObj);
    if (detailsObj.isValidXML) {
      const validData = xmlParser.checkMandatoryFields(detailsObj);
      setMandatoryFlag(validData);
      if (validData) {
        detailsObj.deviceImei = getRandomImei()
        const article_device_make = await getDeviceDetail(detailsObj);
        fetched_device_make = article_device_make;
        console.log("fetched_device_make",fetched_device_make)
      } else {
        saveDescriptionAsBrandModel(detailsObj);
        saveDeviceToStore(detailsObj)
      }
      getProductDetail(detailsObj.deviceRrp || false, fetched_device_make || false, mode);  // added false if device rrp is not present so that we can show product and will hide price using flag
      saveCustomerDetail(detailsObj);
      saveOrderDetail(detailsObj);

      if (validData) {
        detailsObj.deviceImei = getRandomImei()
        getDeviceDetail(detailsObj);
      } else {
        saveDescriptionAsBrandModel(detailsObj);
        saveDeviceToStore(detailsObj)
      }
    }
    else {
      getProductDetail(false);   //false as device rrp sent to prod config so to hide prices of product
      setMandatoryFlag(false);   //to disable learn more button
    }
  }

  const getRandomImei = () => {
    return Math.floor(Math.random() * 1000000000000000);
  }

  const saveOrderDetail = (detailsObj) => {
    orderStore.updateOrderDetail(detailsObj);
  }


  const getXMLFromToken = (token, mode) => {
    const url = `${process.env.GET_XML_API_URL}?token=${token}`;
    const method_options = {
      method: 'GET',
      headers: {
        "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
        "bolt-tenant-id": constants.HEADER_REQUEST.boltTenantId,
        "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode
      }
    }
    loaderStore.showLoader(true);
    fetch(url, method_options)
      .then(res => res.json())
      .then(async response => {
        let fetched_device_make = '';
        console.log("Response from xml api", response);
        loaderStore.showLoader(false);
        if (response._status.code === '80001') {
          const detailsObj = xmlParser.getParsedXMLObj((response.xmlResponse));
          console.log("valid xml", detailsObj.isValidXML);
          if (detailsObj.isValidXML) {
            const validData = xmlParser.checkMandatoryFields(detailsObj);
            console.log("mandatory check", validData);
            setMandatoryFlag(validData);
            console.log("Mode: ", mode);
            if (validData) {
              saveCustomerDetail(detailsObj);
              saveOrderDetail(detailsObj);
              const article_device_make = await getDeviceDetail(detailsObj);
              fetched_device_make = article_device_make;
            } else {
              saveDescriptionAsBrandModel(detailsObj);
              saveDeviceToStore(detailsObj)
            }
            getProductDetail(detailsObj.deviceRrp || false, fetched_device_make || false, mode);  // added false if device rrp is not present so that we can show product and will hide price using flag
          }
          else {
            getProductDetail(false, mode);   //false as device rrp sent to prod config so to hide prices of product
            setMandatoryFlag(false);   //to disable learn more button
          }
        }
        else {
          // console.log("came here");
          getProductDetail(false, mode);   //no device rrp sent to hide price
          setMandatoryFlag(false);   //to disable learn more button
        }
      })
      .catch(err => {
        loaderStore.showLoader(false);
        console.log("Came in error in xml api", err);
        getProductDetail(false, mode);   //no device rrp sent to hide price
        setMandatoryFlag(false);   //to disable learn more button
      })
  }

  const saveCustomerDetail = (detailsObj) => {
    customerStore.updateCustomerDetail(detailsObj);
  }

  const getProductDetail = (deviceRrp, deviceMake, mode) => {
    console.log("Device RRP: {}", deviceRrp);
    const fetchedProduct = JSON.parse(JSON.stringify(productList.allProductList.nodes))
    .filter((item) => {
      if (!deviceMake || deviceMake.trim() === "") {
        return !constants.ACS_PRODUCT_IDS.includes(item.productId); // If phoneDescription is null, undefined, or blank, include non-apple products
    }
     return deviceMake.toLowerCase().includes("apple") ? constants.ACS_PRODUCT_IDS.includes(item.productId) : !constants.ACS_PRODUCT_IDS.includes(item.productId);
  })


    console.log("Product List: {}",fetchedProduct);
    let productArray = ["ATDREIAUSADLDEW24", "ATDREIAUSADLDEWTHEFT24","ATDREIAUSADLDEW1201", "ATDREIAUSADLDEW12", "ATDREIAUSADLDEWTHEFT1201", "ATDREIAUSADLDEWTHEFT12", "ATDREIAUSADLDEW101", "ATDREIAUSADLDEWTHEFT101"];
    const sortedArray = fetchedProduct.sort((a, b) => productArray.indexOf(a.productId) - productArray.indexOf(b.productId)); //sort according product list array
    planStore.saveProductArrToStore(sortedArray);
    console.log("Plan Store: {}", planStore);
    const bandList = deviceRrp && ProdConfig.getBandList([Number(deviceRrp)], sortedArray[0]);
    bandList && planStore.saveBandList(bandList);
    const selectedPlanId = bandList && bandList.length && bandList[0].planId;
    const productArr = ProdConfig.getFinalProducts(sortedArray, selectedPlanId, true);  //sending false in planId if no device rrp present
    console.log("Final product Array: {}", productArr);
    productArr && planStore.saveProductDisplayArrToStore(productArr);
    planStore.saveMode(mode);
  }

  const saveDeviceToStore = (deviceDetail) => {
    deviceStore.updateDeviceDetail(deviceDetail);
  }

  const saveDescriptionAsBrandModel = (detailsObj) => {
    detailsObj['deviceMake'] = detailsObj.phoneDescription;
    detailsObj['deviceModel'] = detailsObj.phoneDescription;
    detailsObj['skuCode'] = detailsObj.phoneDescription;
    return detailsObj;
  }

  //calling kbx api
  const getDeviceDetail = async (detailsObj) => {
    return new Promise((resolve, _)=>{
    loaderStore.showLoader(true);
    const url = process.env.ARTICLE_MASTER_API_URL + '?item_code=' + detailsObj.kbxCode;
    const method_options = {
      method: 'GET',
      headers: {
        "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
        "bolt-tenant-id": constants.HEADER_REQUEST.boltTenantId,
        "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode
      }
    }
    fetch(url, method_options)
      .then(res => res.json())
      .then(response => {
        loaderStore.showLoader(false);
        console.log("Response", response);
        if (response._status.code === '80001') {
          //save device make and model to store
          const { device_make, device_model, sku_code } = response;
          if (device_make && device_model && sku_code) {
            detailsObj['deviceMake'] = device_make;
            detailsObj['deviceModel'] = device_model;
            detailsObj['skuCode'] = sku_code;
          } 
          else if(device_make && device_model && !sku_code && count <= 3) {  //retrying article master
            detailsObj['deviceMake'] = device_make;
            detailsObj['deviceModel'] = device_model;
            count = count + 1;
            console.log("article api counter", count);
            getDeviceDetail(detailsObj);
          }
          else {
            detailsObj = saveDescriptionAsBrandModel(detailsObj);
          }

        } else if(count <= 3){   //retrying article master
          detailsObj = saveDescriptionAsBrandModel(detailsObj);
          count = count +1;
          console.log("article api counter", count);
          getDeviceDetail(detailsObj);
          console.log("error in device info api");
        }
        (detailsObj.deviceMake && detailsObj.deviceModel) ? saveDeviceToStore(detailsObj) : setMandatoryFlag(false);
        resolve (detailsObj.deviceMake)
      })
      .catch(err => {
        if(count <= 3){  //retying article master
          count = count + 1;
          console.log("article api counter", count);
          getDeviceDetail(detailsObj);
        }
        else {
          detailsObj = saveDescriptionAsBrandModel(detailsObj);
          (detailsObj.deviceMake && detailsObj.deviceModel) ? saveDeviceToStore(detailsObj) : setMandatoryFlag(false);
          loaderStore.showLoader(false);
          console.log("error in device info api", err);
        }
      })
    })
  }

  return (
    <div className="landing-page">
      <Layout header={true} footer={true}>
        <LandingComponent canCustomerProceed={isCustomerCanProceed}></LandingComponent>
      </Layout>
    </div>
  )
}

export default IndexPage;